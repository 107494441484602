@font-face {
  font-family: Inter;
  src: local(Inter-Light), url(./fonts/Inter-Light.woff2) format('woff2'),
    url(./fonts/Inter-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Regular), url(./fonts/Inter-Regular.woff2) format('woff2'),
    url(./fonts/Inter-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Medium), url(./fonts/Inter-Medium.woff2) format('woff2'),
    url(./fonts/Inter-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-SemiBold), url(./fonts/Inter-SemiBold.woff2) format('woff2'),
    url(./fonts/Inter-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Bold), url(./fonts/Inter-Bold.woff2) format('woff2'),
    url(./fonts/Inter-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

body {
  font-family: Inter, Helvetica, sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-synthesis: none;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  background-color: white;
  color: #212121;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
}

textarea,
input {
  outline: none;
}

button:focus {
  outline: none;
}

input,
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.hover-bold:hover {
  font-weight: bold;
}

.hover-underline:hover {
  text-decoration: underline;
}

.loading-dots {
  animation: pulse 1000ms infinite ease-in-out;
  position: absolute;
  overflow: hidden;
  width: 0px;
}

button,
a {
  cursor: pointer;
}

@keyframes pulse {
  100% {
    width: 1.25em;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}
